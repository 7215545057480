























































































































































import { Vue, Provide, Component } from 'vue-property-decorator';
import Tarbar from '@/components/tarbar.vue';
import { Toast } from 'vant';
import serve from '@/serve/home.ts';
interface Type {
  key: string;
}
@Component({
  components: {
    Tarbar,
  },
})
export default class Home extends Vue {
  showDia = false;
  screenWidth = '';
  QQdata = [];
  bannerH: string | number | null = '';
  @Provide() searchType = '商品';
  @Provide() bannerList = [];
  @Provide() shopData = [];
  @Provide() goodsData = [];
  @Provide() img = require('@/assets/img/store.jpg');
  @Provide() goods = require('@/assets/img/goods.jpg');
  @Provide() keyword = '';
  @Provide() show = false;
  @Provide() columns = [
    {
      key: '商品',
    },
    {
      key: '店铺',
    },
  ];

  created() {
    this.getCarousel();
    this.getShops();
    this.getMerchandise();
    this.bannerH = document.body.clientWidth / 3;
  }

  async getCarousel() {
    // 获取轮播图
    const res = await serve.getCarousel();
    if (res.data.code === 0) {
      this.bannerList = res.data.data;
    } else {
      Toast(res.data.message);
    }
  }
  chooseQQ(val: object): void {
    this.QQdata = val['qq'];
    this.showDia = true;
  }
  openQQ(val?: string): void {
    window.location.href = `mqqwpa://im/chat?chat_type=wpa&uin=${val}&version=1&src_type=web&web_src=oicqzone.com`;
  }
  async collect(id: string) {
    const res = await serve.collectShop(id);
    if (res.data.code === 0) {
      Toast('收藏成功');
      this.getShops();
    } else {
      Toast(res.data.message);
    }
  }
  async cancelCollect(id: string) {
    const res = await serve.cancleCollect(id);
    if (res.data.code === 0) {
      Toast('取消收藏成功');
      this.getShops();
    } else {
      Toast(res.data.message);
    }
  }
  async getMerchandise() {
    const res = await serve.getMerchandise({ keyword: this.keyword });
    if (res.data.code === 0) {
      res.data.data.content.map((item: object) => {
        item['pic'] = item['pictures'].split(',');
      });
      this.goodsData = res.data.data.content;
    } else {
      Toast(res.data.message);
    }
  }
  async getShops() {
    // 获取推荐商家
    const res = await serve.getShops({ keyword: this.keyword });
    if (res.data.code === 0) {
      this.shopData = res.data.data.content;
    } else {
      Toast(res.data.message);
    }
  }

  private onCancel(): void {
    this.show = false;
  }

  private onConfirm(val: Type): void {
    this.searchType = val.key;
    this.show = false;
  }

  private goTo(val: string): void {
    this.$router.push(val);
  }

  private search(): void {
    if (this.searchType == '商品') {
      this.$router.push(`/searchGoods?keyword=${this.keyword}`);
    } else if (this.searchType == '店铺') {
      this.$router.push(`/searchStore?keyword=${this.keyword}`);
    }
  }
}
